import {useAxiosClient} from "~/use/axios/client";
import {
    type GmDtoLibraryDtoTrackDamageForestOperationContractTrackDamages,
    type GmModelLibraryModelsTrackDamageOverviewQuery,
    type GmModelLibraryModelsTrackDamageTrackDamageUpdateInputDto,
    TrackDamageApi
} from "~/gen/openapi/sblService";
import {extractErrorMessagesFromErrorOrResponse} from "~/helpers/errors";
import {TRACK_DAMAGE_REPAIR_STATUS_REJECTED} from "~/use/trackDamage/constants";
import {useLocalstorage} from "~/use/localStorage/store";
import {useUserStore} from "~/use/user/store";
import {cloneDeep} from "lodash";

const DEFAULT_FILTER: GmModelLibraryModelsTrackDamageOverviewQuery = Object.freeze({
    Managers: ['MYSELF']
})

const { persist } = useLocalstorage()

const state = reactive({
    operationsWithTrackDamage: [] as Array<GmDtoLibraryDtoTrackDamageForestOperationContractTrackDamages>,
    // separate list to avoid notifications being based on filters + issues with filters when data is updated in background
    filteredOperationsWithTrackDamage: [] as Array<GmDtoLibraryDtoTrackDamageForestOperationContractTrackDamages>,
    trackDamageFetchError: '',
    isLoadingOperationsWithTrackDamage: false,
    filter: persist<GmModelLibraryModelsTrackDamageOverviewQuery>('trackDamageListFilterValue', {...DEFAULT_FILTER}),
})
const { axiosClient } = useAxiosClient()

const operationsWithTrackDamage = computed(() => state.operationsWithTrackDamage)
const filteredOperationsWithTrackDamage = computed(() => state.filteredOperationsWithTrackDamage)
const trackDamageFetchError = computed(() => state.trackDamageFetchError)
const isLoadingOperationsWithTrackDamage = computed(() => state.isLoadingOperationsWithTrackDamage)
const filter = computed({
    get: () => state.filter,
    set: (value: GmModelLibraryModelsTrackDamageOverviewQuery) => {
        state.filter = value
    }
})

const confirmedOperationsWithTrackDamage = computed(() => {
    // @ts-ignore
    return operationsWithTrackDamage.value.filter((operation) => operation.TrackDamageRepairStatus >= 1)
})

const unconfirmedOperationsWithTrackDamage = computed(() => {
    return operationsWithTrackDamage.value.filter((operation) => !confirmedOperationsWithTrackDamage.value.includes(operation))
})

const confirmedFilteredOperationsWithTrackDamage = computed(() => {
    // @ts-ignore
    return filteredOperationsWithTrackDamage.value.filter((operation) => operation.TrackDamageRepairStatus >= 1)
})

const unconfirmedFilteredOperationsWithTrackDamage = computed(() => {
    return filteredOperationsWithTrackDamage.value.filter((operation) => !confirmedFilteredOperationsWithTrackDamage.value.includes(operation))
})

const resetFilter = (): void => {
    filter.value = cloneDeep(DEFAULT_FILTER)
}

const loadOperationsWithTrackDamage = async (useFilter?: boolean) => {
    const { currentUser } = useUserStore()
    if (!currentUser.value?.Id) {
        return
    }

    const transformedFilter: GmModelLibraryModelsTrackDamageOverviewQuery = useFilter === true ? {
        ...filter.value,
        Managers: filter.value?.Managers?.map(manager => manager === 'MYSELF' ? currentUser.value?.Id : manager) as string[]
    } : { Managers: [currentUser.value?.Id] }

    state.isLoadingOperationsWithTrackDamage = true
    const trackDamageApi = new TrackDamageApi(undefined, '', axiosClient.value)
    trackDamageApi.trackDamageGetTrackDamageOverview(transformedFilter).then((response) => {
        if (useFilter === true) {
            state.filteredOperationsWithTrackDamage = response.data.ForestOperationContracts || []
        }

        if (!useFilter || JSON.stringify(filter.value) === JSON.stringify(DEFAULT_FILTER)) {
            state.operationsWithTrackDamage = response.data.ForestOperationContracts || []
        }
    }).catch((error) => {
        state.trackDamageFetchError = extractErrorMessagesFromErrorOrResponse(error)[0]
    }).finally(() => {
        state.isLoadingOperationsWithTrackDamage = false
    })
}


const declineOperation = async (forestOperationContractId: string, statusDescription: string) => {
    const trackDamageApi = new TrackDamageApi(undefined, '', axiosClient.value)
    await trackDamageApi.trackDamageUpdateTrackDamage(forestOperationContractId, {
        ForestOperationContractId: forestOperationContractId,
        TrackDamageStatusDescription: statusDescription,
        // @ts-ignore
        Status: TRACK_DAMAGE_REPAIR_STATUS_REJECTED
    }).then(() => {
        loadOperationsWithTrackDamage(true)
    })
}

const confirmOperation = async (operationConfirmation: GmModelLibraryModelsTrackDamageTrackDamageUpdateInputDto) => {
    const trackDamageApi = new TrackDamageApi(undefined, '', axiosClient.value)
    await trackDamageApi.trackDamageUpdateTrackDamage(operationConfirmation.ForestOperationContractId!, operationConfirmation).then(() => {
        loadOperationsWithTrackDamage(true)
    })
}


export const useTrackDamageStore = () => ({
    operationsWithTrackDamage,
    trackDamageFetchError,
    isLoadingOperationsWithTrackDamage,
    loadOperationsWithTrackDamage,
    declineOperation,
    confirmOperation,
    confirmedOperationsWithTrackDamage,
    unconfirmedOperationsWithTrackDamage,
    confirmedFilteredOperationsWithTrackDamage,
    unconfirmedFilteredOperationsWithTrackDamage,
    filter,
    resetFilter,
})