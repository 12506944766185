import {
    ActionModelsLibraryBaseResponsibleParty,
} from "~/gen/openapi/sblService";


export const TRACK_DAMAGE_RESPONSIBLE_NOT_RELEVANT = 0
export const TRACK_DAMAGE_RESPONSIBLE_FOREST_OWNER = 1
export const TRACK_DAMAGE_RESPONSIBLE_GM_SKOG = 2
export const trackDamageResponsibilityTypeMap: {[key: number]: ActionModelsLibraryBaseResponsibleParty} = {
    [TRACK_DAMAGE_RESPONSIBLE_NOT_RELEVANT]: ActionModelsLibraryBaseResponsibleParty.NotRelevant,
    [TRACK_DAMAGE_RESPONSIBLE_FOREST_OWNER]: ActionModelsLibraryBaseResponsibleParty.ForestOwner,
    [TRACK_DAMAGE_RESPONSIBLE_GM_SKOG]: ActionModelsLibraryBaseResponsibleParty.GmSkogSa,
}

export const TRACK_DAMAGE_COST_NOT_RELEVANT = 0
export const TRACK_DAMAGE_COST_FOREST_OWNER = 1
export const TRACK_DAMAGE_COST_GM_SKOG = 2
export const trackDamageCostTypeMap: {[key: number]: ActionModelsLibraryBaseResponsibleParty} = {
    [TRACK_DAMAGE_COST_NOT_RELEVANT]: ActionModelsLibraryBaseResponsibleParty.NotRelevant,
    [TRACK_DAMAGE_COST_FOREST_OWNER]: ActionModelsLibraryBaseResponsibleParty.ForestOwner,
    [TRACK_DAMAGE_COST_GM_SKOG]: ActionModelsLibraryBaseResponsibleParty.GmSkogSa,
}

/** TRACK DAMAGE ORDER RESPONSIBLE DEPARTMENT **/
export const TRACK_DAMAGE_RESPONSIBLE_DEPARTMENT_FOREST_MANAGER = 0
export const TRACK_DAMAGE_RESPONSIBLE_DEPARTMENT_SILVICULTURE_DEPARTMENT = 1

/** ORDER TYPES **/
export const TRACK_DAMAGE_ORDER_TYPE_GROUND_PREPARATION = 1
export const TRACK_DAMAGE_ORDER_TYPE_YOUNG_TREE_CARE = 2
export const TRACK_DAMAGE_ORDER_TYPE_PLANTING = 3
export const TRACK_DAMAGE_ORDER_TYPE_SPAYING_ = 4
export const TRACK_DAMAGE_ORDER_TYPE_DIGGING = 5
export const TRACK_DAMAGE_ORDER_TYPE_ADMINISTRATION = 6
export const TRACK_DAMAGE_ORDER_TYPE_STEM_PRUNING = 7
export const TRACK_DAMAGE_ORDER_TYPE_PLANT_SALE = 8
export const TRACK_DAMAGE_ORDER_TYPE_ADDITIVE_PLANTING = 9
export const TRACK_DAMAGE_ORDER_TYPE_PLANTATION_CONTROL = 10
export const TRACK_DAMAGE_ORDER_TYPE_NO_PROPERTY = 11
export const TRACK_DAMAGE_ORDER_TYPE_FERTILIZATION = 12
export const TRACK_DAMAGE_ORDER_TYPE_PRE_CLEARING_BEFORE_FINAL_FELLING = 13
export const TRACK_DAMAGE_ORDER_TYPE_PRE_CLEARING_BEFORE_FINAL_THINNING = 14
export const TRACK_DAMAGE_ORDER_TYPE_GROUND_PREPARATION_WITH_EXCAVATOR = 15
export const TRACK_DAMAGE_ORDER_TYPE_GROUND_PREPARATION_WITH_SOWING = 16
export const TRACK_DAMAGE_ORDER_TYPE_LOGGING = 20
export const TRACK_DAMAGE_ORDER_TYPE_THINNING = 30
export const TRACK_DAMAGE_ORDER_TYPE_BIO_GROT_HELTRE = 40
export const TRACK_DAMAGE_ORDER_TYPE_ROAD_PLANNER = 41
export const TRACK_DAMAGE_ORDER_TYPE_TRACK_REPAIR = 50

/** ORDER STATUSES **/
export const TRACK_DAMAGE_ORDER_STATUS_NEW = 1
export const TRACK_DAMAGE_ORDER_STATUS_CONFIRMATION_SENT = 2
export const TRACK_DAMAGE_ORDER_STATUS_IN_PROGRESS = 3
export const TRACK_DAMAGE_ORDER_STATUS_NO_MONEY = 4
export const TRACK_DAMAGE_ORDER_STATUS_INVOICED = 10000
export const TRACK_DAMAGE_ORDER_STATUS_COMPLETE = 100001
export const TRACK_DAMAGE_ORDER_STATUS_PARTIAL = 100002
export const TRACK_DAMAGE_ORDER_STATUS_SEND_TO_VISMA_AS_STANDARD_ORDER = 200000
export const TRACK_DAMAGE_ORDER_STATUS_PROVISIONED = 961700000
export const TRACK_DAMAGE_ORDER_STATUS_ASSIGNED_TO_ENTREPRENEUR = 961700001
export const TRACK_DAMAGE_ORDER_STATUS_SMS_SENT = 961700002
export const TRACK_DAMAGE_ORDER_STATUS_COMPLETED_BY_ENTREPRENEUR = 961700003
export const TRACK_DAMAGE_ORDER_STATUS_NOT_LOGGED = 961700004
export const TRACK_DAMAGE_ORDER_STATUS_CANCELLED_BY_FOREST_OWNER = 961700005
export const TRACK_DAMAGE_ORDER_STATUS_REALLOCATED_AREA = 961700006
export const TRACK_DAMAGE_ORDER_STATUS_REJECTED_BY_ENTREPRENEUR = 961700007
export const TRACK_DAMAGE_ORDER_STATUS_ACCEPTED_BY_ENTREPRENEUR = 961700008
export const TRACK_DAMAGE_ORDER_STATUS_AUTHORIZED_BY_ENTREPRENEUR = 961700009
export const TRACK_DAMAGE_ORDER_STATUS_OTHER = 961700010
export const TRACK_DAMAGE_ORDER_STATUS_SEND_TO_VISMA_AS_BASIS_FOR_INVOICE = 961700011

/** TRACK DAMAGE REPAIR STATUSES **/
export const TRACK_DAMAGE_REPAIR_STATUS_NOT_RELEVANT = 0
export const TRACK_DAMAGE_REPAIR_STATUS_APPROVED = 1
export const TRACK_DAMAGE_REPAIR_STATUS_REJECTED = 2
export const TRACK_DAMAGE_REPAIR_STATUS_REPAIRED_BY_GM = 3
export const TRACK_DAMAGE_REPAIR_STATUS_REPAIRED_BY_FOREST_OWNER = 4
export const TRACK_DAMAGE_REPAIR_STATUS_REJECTED_BY_ENTREPRENEUR = 5
